import React from "react";
import "./style.css"
import LeftContent from "./left";
import Admin from "./testa";
import RightContent from "./right";
import MainMenue from "./nav";
const Dashboard = () => {
  return (
    
  
 
     <Admin/>
   
  );
};

export default Dashboard;