import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">

          <TablesWrapper className="flexSpaceNull">
          <TableBox>
                        <PricingTable
                action="hello@browse-index.com"
               actionText="Email us"
                price=""
                title="IndEx Economy"
                brac="(recommended for individual or small groups of students)"
                text="Paid per individual project you work on"
                offers={[
                  { name: "Access to project resources (i.e. project briefs, links and explainer videos (where available)", cheked: true },
                  { name: "Team structure (Individual or up to 5 students max)", cheked: true },
                  { name: "Brainstorm ideas and develop mock-ups or designs to present.", cheked: true },
                  { name: "Upload and share designs or mock-ups with Industry professionals.", cheked: true },
                  { name: "Students receive guidance and feedback from Industry and/or academic supervisors.", cheked: true },
                  {name:"Pay by Credit Card only.", checked:true}
                ]}
                 
              />
            </TableBox>
            <TableBox>
              <PricingTable
               action="hello@browse-index.com"
               actionText="Email us"
                price=""
                title="IndEx Plus" brac="(recommended for larger groups of students who want to work together)"
                text="Everything in the Economy Plan plus:"
                offers={[
                  { name: "Team structure (6-9 students)", cheked: true },
                  { name: "Students receive up to 4 hours of industry engagement via Teams or Zoom", cheked: true },
                  { name: "Students surveyed to rate and give feedback on their Industry experience project.", cheked: true },
                  { name: "Regular guidance and monitoring of student progress against project brief", cheked: true },
                  { name: "Students present final output to Industry and/or academic supervisors.", cheked: true },

{name:"Pay by Card or Invoice.", cheked:true}


                ]}
                 
              />
          </TableBox>
        
            <TableBox>
<PricingTable
  
  price=""
  title="IndEx Enterprise"
  action ="https://ni-experiences.com/support@ni-experiences.com"
  actionText= "Email us"
  text="Everything in the Plus"
  offers={[
    { name: "Team structure (100% customisable)", cheked: true },
    { name: "Project collaboration enabled with students from another education provider, culture and/or country to further expand learning. collaboration and knowledge exchange.", cheked: true },
    { name: "Students and academics work on projects of their choice.", cheked: true },
    { name: "On-the-ground student project support through user research.", cheked: true },
    { name: "Customised in-person or tailored remote experiences for education providers, their academics and their students.", cheked: true },
  {  name:"Pay by Card or Invoice.", cheked:true }
  ]}
   
/>
</TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




