import React from "react";
// Sections
import PartnerHeader from "../components/Sections/PartnerHeader"
import MoreProjects from "../components/Sections/moreProjects";
import Psection from "../components/Sections/partnerSection";
const Partner= () => {
    return (
        <>
            <PartnerHeader/>
            <Psection/>

        </>
    )
}

export default Partner