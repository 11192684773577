import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";


export default function AddProject() {
  
  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.7/css/materialize.min.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  
  
  const [formData, setFormData] = useState({
    companyName: '',
    companyRegNumber: '',
    companyAddress: '',
    contactFirstName: '',
    contactSurname: '',
    contactNumber: '',
    workEmail: '',
    hearAboutUs: '',
    projectTitle: '',
    projectDescription: '',
    projectOutcome: '',
    projectSuitableFor: '',
    minTeamSize: '',
    maxTeamSize: '',
    teamPreference: '',
    workingFormat: '',
    additionalActivities: '',
    incentives: '',
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const nextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

const renderStep = () => {
  switch (currentStep) {
    case 1:
      return (
        <>
          <div className="row">
            <div className="whiteBg ">
                        <HeaderInfo>
            <h1 className="font40 extraBold">Submit Your Industry Project</h1>
            <p className="font20">
Does Your Organisation need help on a project?  Submit your information using the form below and our team will review and be in touch.
            </p>
          </HeaderInfo>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="companyName" name="companyName" type="text" value={formData.companyName} onChange={handleChange} required />
              <label htmlFor="companyName">Company Name</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="companyRegNumber" name="companyRegNumber" type="number" value={formData.companyRegNumber} onChange={handleChange} required />
              <label htmlFor="companyRegNumber">Company Registration Number</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="companyAddress" name="companyAddress" type="text" value={formData.companyAddress} onChange={handleChange} required />
              <label htmlFor="companyAddress">Company Address</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="contactFirstName" name="contactFirstName" type="text" value={formData.contactFirstName} onChange={handleChange} required />
              <label htmlFor="contactFirstName">Contact First Name</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="contactSurname" name="contactSurname" type="text" value={formData.contactSurname} onChange={handleChange} required />
              <label htmlFor="contactSurname">Contact Surname</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="contactNumber" name="contactNumber" type="text" value={formData.contactNumber} onChange={handleChange} required />
              <label htmlFor="contactNumber">Contact Number (with country code)</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="workEmail" name="workEmail" type="email" value={formData.workEmail} onChange={handleChange} required />
              <label htmlFor="workEmail">Work Email</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <p>How did you hear about us?</p>
              <div>
                <p>
                  <input name="hearAboutUs" type="radio" id="socialMedia" value="socialMedia" onChange={handleChange} required />
                  <label htmlFor="socialMedia">Social media</label>
                </p>
                <p>
                  <input name="hearAboutUs" type="radio" id="colleague" value="colleague" onChange={handleChange} required />
                  <label htmlFor="colleague">Recommended by a colleague</label>
                </p>
                <p>
                  <input name="hearAboutUs" type="radio" id="searchEngine" value="searchEngine" onChange={handleChange} required />
                  <label htmlFor="searchEngine">Through a search engine (e.g., Google, Bing, etc.)</label>
                </p>
                <p>
                  <input name="hearAboutUs" type="radio" id="blog" value="blog" onChange={handleChange} required />
                  <label htmlFor="blog">Through a blog or news article</label>
                </p>
                <p>
                  <input name="hearAboutUs" type="radio" id="event" value="event" onChange={handleChange} required />
                  <label htmlFor="event">Through an indEx event</label>
                </p>
              </div>
            </div>
          </div>

          <StepWrapper>
            <button onClick={nextStep} className='waves-effect waves-light btn-large rounded'>Next</button>
          </StepWrapper>
        </>
      );

    case 2:
      return (
        <>
          <div className="row">
            <div className="input-field col s12">
              <input id="projectTitle" name="projectTitle" type="text" value={formData.projectTitle} onChange={handleChange} required />
              <label htmlFor="projectTitle">Project Title</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <textarea id="projectDescription" name="projectDescription" className="materialize-textarea" value={formData.projectDescription} onChange={handleChange} required></textarea>
              <label htmlFor="projectDescription">Project Description</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <textarea id="projectOutcome" name="projectOutcome" className="materialize-textarea" value={formData.projectOutcome} onChange={handleChange} required></textarea>
              <label htmlFor="projectOutcome">Ideal Project Outcome</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <p>Suitable for:</p>
              <div>
                <p>
                  <input name="projectSuitableFor" type="radio" id="undergraduate" value="undergraduate" onChange={handleChange} required />
                  <label htmlFor="undergraduate">Undergraduate students</label>
                </p>
                <p>
                  <input name="projectSuitableFor" type="radio" id="master" value="master" onChange={handleChange} required />
                  <label htmlFor="master">Master students</label>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="minTeamSize" name="minTeamSize" type="number" value={formData.minTeamSize} onChange={handleChange} required />
              <label htmlFor="minTeamSize">Minimum Team Size</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input id="maxTeamSize" name="maxTeamSize" type="number" value={formData.maxTeamSize} onChange={handleChange} required />
              <label htmlFor="maxTeamSize">Maximum Team Size</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <p>Team Preference:</p>
              <div>
                <p>
                  <input name="teamPreference" type="radio" id="single" value="single" onChange={handleChange} required />
                  <label htmlFor="single">Single team</label>
                </p>
                <p>
                  <input name="teamPreference" type="radio" id="multiple" value="multiple" onChange={handleChange} required />
                  <label htmlFor="multiple">Multiple teams</label>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <p>Preferred Working Format:</p>
              <div>
                <p>
                  <input name="workingFormat" type="radio" id="onsite" value="onsite" onChange={handleChange} required />
                  <label htmlFor="onsite">Onsite</label>
                </p>
                <p>
                  <input name="workingFormat" type="radio" id="remote" value="remote" onChange={handleChange} required />
                  <label htmlFor="remote">Remote</label>
                </p>
                <p>
                  <input name="workingFormat" type="radio" id="hybrid" value="hybrid" onChange={handleChange} required />
                  <label htmlFor="hybrid">Hybrid</label>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <textarea id="additionalActivities" name="additionalActivities" className="materialize-textarea" value={formData.additionalActivities} onChange={handleChange} required></textarea>
              <label htmlFor="additionalActivities">Additional Activities</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <textarea id="incentives" name="incentives" class="materialize-textarea" value={formData.incentives} onChange={handleChange} required></textarea>
<label htmlFor="incentives">Incentives</label>
</div>
</div>
<StepWrapper>
        <button onClick={prevStep} className='waves-effect waves-light btn-large'>Back</button>
        <button type="submit" className='waves-effect waves-light btn-large' disabled={loading}>
          {loading ? 'Processing...' : 'Submit'}
        </button>
      </StepWrapper>
    </>
  );

default:
  return null;
}
};



const handleSubmit = async (e) => {
  e.preventDefault();

  // Validation logic
  const requiredFields = {
    companyName: 'Company Name',
    companyRegNumber: 'Company Registration Number',
    companyAddress: 'Company Address',
    contactFirstName: 'Contact First Name',
    contactSurname: 'Contact Surname',
    contactNumber: 'Contact Number',
    workEmail: 'Work Email',
    hearAboutUs: 'How did you hear about us?',
    projectTitle: 'Project Title',
    projectDescription: 'Project Description',
    projectOutcome: 'Ideal Project Outcome',
    projectSuitableFor: 'Suitable for',
    minTeamSize: 'Minimum Team Size',
    maxTeamSize: 'Maximum Team Size',
    teamPreference: 'Team Preference',
    workingFormat: 'Preferred Working Format',
    additionalActivities: 'Additional Activities',
    incentives: 'Incentives'
  };

  for (let field in requiredFields) {
    if (formData[field].trim() === '') {
      setError(`Please fill in the ${requiredFields[field]} field.`);
      return; // Prevent submission
    }
  }

  setError('');
  setSuccess(false);
  try {
    setLoading(true);

    const response = await axios.post('https://browse-index.com/server/addProject.php', formData);

    setFormData({
      companyName: '',
      companyRegNumber: '',
      companyAddress: '',
      contactFirstName: '',
      contactSurname: '',
      contactNumber: '',
      workEmail: '',
      hearAboutUs: '',
      projectTitle: '',
      projectDescription: '',
      projectOutcome: '',
      projectSuitableFor: '',
      minTeamSize: '',
      maxTeamSize: '',
      teamPreference: '',
      workingFormat: '',
      additionalActivities: '',
      incentives: ''
    });

    setLoading(false);
    setError('');
    setSuccess(true);
    console.log(response);
  } catch (error) {
    setError('An error occurred. Please try again.');
    setLoading(false);
  }
};
  return (
       <div class="ctrlqFormContentWrapper">
      <div class="ctrlqHeaderMast"></div>
      <div class="ctrlqCenteredContent">
        <div class="ctrlqFormCard">
          <div class="ctrlqAccent"></div>
          <div class="ctrlqFormContent">
{success ?(
<div className="success-message">
        <h2>Thank you for submitting your project!</h2>
        <p>We will review your submission and get back to you soon.</p>
      </div>

):(
                         <form onSubmit={handleSubmit}>
                {renderStep()}
                <br />
                {error && <p className="red">{error}</p>}

              </form>
              
)
}
          </div>
        </div>
      </div>
    </div>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 100px;
  @media (max-width: 860px) {
    margin-top: 50px;
  }
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;


const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;


const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

