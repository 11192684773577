import React, { useState } from 'react';


const FeatureItem = ({ feature }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  // Function to format content with HTML line breaks for paragraphs
  const formatContentWithParagraphs = (content) => {
    return content.split('\n').map((paragraph, index) => (
      <p key={index} className="paragraph">{paragraph}</p>
    ));
  };

  return (
    <>
      <div className="card-team" onClick={toggleModal}>
        <div className="card-team_img">
          <img src={feature.logo} alt="" />
        </div>
        <div className="card-team_body">
          <span className="card-team_title">{feature.name}</span>
          <h6 className="designation">{feature.title}</h6>
          <a>Read bio ></a>
        </div>
      </div>

      {showModal && (
      <>
      <div className="mod-l" onClick={toggleModal}></div>
        <div className="mod">
          <div className="mod-content">
            <span className="close-btn" onClick={toggleModal}>X</span>
            <span className="modal-title">{feature.name}</span>
            {formatContentWithParagraphs(feature.content)}
            <button className="close-btn" onClick={toggleModal}>Close</button>
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default FeatureItem;