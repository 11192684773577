import React from "react";
import styled from "styled-components";

import Pro1 from "../../assets/img/pro2.png";
import Pro2 from "../../assets/img/pro.png";

export default function AboutMiddle() {
  return (
    <div className="about darkBg">
      <div className="container">
        <Advertising>
          <AddLeft>
            <h2 className="font40 whiteColor textCenter extraBold">Our Philosophy</h2>
            <Magret>
              <div className="flexCenter">
                <img width="80" src={Pro1} alt="Philosophy Icon" />
              </div>
              <ul className="">
                <li>
                  We believe industry plays a huge role in producing job-ready graduates for the present and the future.
                </li>
                <li>
                  We believe industry should shape what is taught in the classroom. We make finding talent and industry engagement opportunities easy.
                </li>
                <li>
                  We put the needs of both the students, universities, and industry at the heart of everything we do.
                </li>
              </ul>
            </Magret>

            <br /><br />

            <h2 className="font40 whiteColor textCenter extraBold">Our Platform</h2>
            <Magret>
              <div className="flexCenter">
                <img width="80" src={Pro2} alt="Platform Icon" />
              </div>
              <p>
                IndEx powerfully enables collaboration between higher education providers, their students, and Industry. Through our platform, subscribers can browse and book experiential learning opportunities to work on real-world projects, supporting businesses and other organizations, and ultimately helping communities in developing countries and around the world.
              </p>
            </Magret>
          </AddLeft>
        </Advertising>
      </div>
    </div>
  );
}

const Magret = styled.div`
  position: relative;
  z-index: 1;
  padding: 30px;
  background-color: white;
  color: black;
  border-radius: 20px;
  margin: 0;
`;

const Advertising = styled.div`
  margin: 80px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  position: relative;

  @media (max-width: 860px) {
    flex-direction: column;
    padding: 30px 0;
    margin: 80px 0;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  align-items: center;
  h2 {
    text-align: center;
  }

  @media (max-width: 860px) {
    width: 80%;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
  }
`;