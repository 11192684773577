import React from "react";


import Add from "../components/Sections/add";


export default function  AddProject() {
  return (
    <>
      <Add/>
    </>
  );
}


