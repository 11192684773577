import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
 const [loaded, setLoaded] = useState(true)
  useEffect(() => {
    axios.get("https://browse-index.com/blog/wp-json/wp/v2/posts?_embed")
      .then(response => {
        const posts = response.data.map(post => ({
          ...post,
          featured_media_url: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : '',
          author_name: post._embedded.author[0].name // Assuming you have author info in the embedded data
        }));
        setBlogs(posts);
        setLoaded(false);
      })
      .catch(error => {
        console.error("There was an error fetching the blog posts!", error);
      });
  }, []);

  return (
  loaded ? (
<div class="spinner-container">
  <div class="spinner"></div>
</div>
  ) : (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Stories</h1>
            <p className="font13">
              We provide actionable tips, strategies, and techniques to improve your industry experience 
            </p>
          </HeaderInfo>
          <BlogGrid>
            {blogs.map(blog => (
              <BlogBox
                key={blog.id}
                title={blog.title.rendered}
                srcz={blog.featured_media_url} // Image URL
                text={blog.excerpt.rendered.replace(/<\/?[^>]+(>|$)/g, "")} // Removing HTML tags from excerpt
                author={blog.author_name} // Update if there is a specific author field from the API
                link={blog.link} // Link to the full blog post
                date={blog.date} // Pass the creation date
              />
            ))}
          </BlogGrid>
        </div>
      </div>
    </Wrapper>
  )
);
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 120px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const BlogGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;