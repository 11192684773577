import React, { useState, useEffect } from "react";
import axios from "axios";

const ProjectSubmit = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(5);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.post("https://browse-index.com/server/fetchsubs.php");
        setProjects(response.data.projects);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProjects();
  }, []);

  const deleteProject = async (projectId) => {
    try {
      // Make a POST request to delete the project from the database
      await axios.post("https://browse-index.com/server/deleteSubs.php", {
        projectId: projectId
      });

      // Update the projects state to remove the deleted project
      setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeeMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  return (
    <section className="transfer-section">
      <div className="transfer-section-header">
        <h2>Incoming Projects</h2>
        <div className="filter-options">
          <button className="button">
            <i className="ph-plus"></i>
          </button>
        </div>
      </div>
      <div className="table-container-scrollable">
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Company Reg. Number</th>
                <th>Company Address</th>
                <th>Contact Name</th>
                <th>Contact Number</th>
                <th>Email</th>
                <th>Heard About Us</th>
                <th>Project Title</th>
                <th>Project Description</th>
                <th>Project Outcome</th>
                <th>Suitable For</th>
                <th>Min Team Size</th>
                <th>Max Team Size</th>
                <th>Team Preference</th>
                <th>Working Format</th>
                <th>Additional Activities</th>
                <th>Incentives</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="18">Loading...</td>
                </tr>
              ) : (
                projects && projects.slice(0, visibleCount).map((project, index) => (
                  <tr key={index}>
                    <td className="break-word">{project.company_name}</td>
                    <td className="break-word">{project.company_reg_number}</td>
                    <td className="break-word">{project.company_address}</td>
                    <td className="break-word">{project.contact_first_name} {project.contact_surname}</td>
                    <td className="break-word">{project.contact_number}</td>
                    <td className="break-word">{project.work_email}</td>
                    <td className="break-word">{project.hear_about_us}</td>
                    <td className="break-word">{project.project_title}</td>
                    <td className="break-word">{project.project_description}</td>
                    <td className="break-word">{project.project_outcome}</td>
                    <td className="break-word">{project.project_suitable_for}</td>
                    <td className="break-word">{project.min_team_size}</td>
                    <td className="break-word">{project.max_team_size}</td>
                    <td className="break-word">{project.team_preference}</td>
                    <td className="break-word">{project.working_format}</td>
                    <td className="break-word">{project.additional_activities}</td>
                    <td className="break-word">{project.incentives}</td>
                    <td>
                      <button className="save-button" onClick={() => deleteProject(project.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {visibleCount < projects.length && (
        <button className="see-more-button" onClick={handleSeeMore}>
          See More
        </button>
      )}
    </section>
  );
};

export default ProjectSubmit;