import React from "react";
import FeatureItem from "./featureItem";


export default function Features() {
  const dataFeatures = [
    {
      id: 1,
      title: "CEO",
      logo: "/mo.png",
      name:"Moses Alicwamu",
      content: "Moses is the visionary behind indEx, an industry experiences platform he hopes can democratize the opportunity for everyone to acquire much-needed skills to succeed in life. \nWith a background in Biosystems and Environmental Engineering, he co-founded ASEI Uganda, and helped commercialize UV-C LED disinfection technology for point of use drinking water applications in Ugandan schools and healthcare facilities. \nHis experience spans product design, developing and leading teams, developing products and services, creating partnerships, marketing and humanitarian water engineering. As an individual with a global mindset, he envisions a future where every student and professional can acquire and utilize new skills to transform themselves, businesses and the communities where they live. ",
    },
    {
      id: 2,
      title: "VP of Marketing",
      name:"Ben Crosariol",
      logo: "/ben.png",
      content:" Ben is a successful senior executive with over 20 years of experience in marketing, communications and sales. A proven and influential leader driving excellence in brand differentiation, digital delivery, transformational change, performance improvement and people management. Ben has a reputation for delivering best-in-class marketing enabling strong results in organisational awareness and reputation, lead generation, revenue growth, cost control, and increased efficiency. During his time, Ben has gained vast experience across a range of industries including Banking, Fintech, Education, Wine, FMCG and Consumer and Business Technology sectors while working for companies such as Canon, ASL, and the University of NSW. Always looking to make a positive change in the world, throughout his career, Ben has provided pro-bono and freelance support and guidance to a range of Not-For-Profit and for purpose organisations that are helping improve the lives of individuals or entire communities in which they operate.",
    },
    {
      id: 3,
      title: "VP of Engineering",
      name: "John Viannie wasswa",
      logo: "/via.jpg",
      content: "John transitioned from a biomedical laboratory technologist to a self-taught software engineer where he developed Cyanase, a social investment infrastructure for the internet in Uganda. To improve Cyanase’s marketing efforts, he developed a twitter bot that recommended Cyanase on posts that mentioned savings and investment, and a tool that could market Cyanase for free on TV stations. He is passionate about developing technology that has an impact on society. ",
    },
  ];

  const content = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: -100, opacity: 0 },
  };

  return (
    <div className="container liBg  mx-auto max-w-[1344px]">
      <div className="px-5 py-16 flex flex-col gap-10 sm:pb-28 sm:px-10 md:pb-36">
        {/* Head Content */}
        <div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={content}
          className="text-center flex flex-col items-center"
        >
          <h2 style={{color:"#022D36"}}className="mb-2">
            Our Team
          </h2>

        </div>

        {/* Feature Items */}
        <div className="container">
          <div className="grid">
            {dataFeatures?.map((feature, index) => (
              <FeatureItem key={feature.id} feature={feature} i={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}