import React from "react";
// Sections
import ProjectHeader from "../components/Sections/ProjectHeader"
import MoreProjects from "../components/Sections/moreProjects";
import Blog from "../components/Sections/Blog";
const Project= () => {
    return (
        <>
            <ProjectHeader/>
               <MoreProjects />
               <Blog/>
          
        </>
    )
}

export default Project