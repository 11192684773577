import React from "react";
import styled from "styled-components";

export default function BlogBox({ title, text, author, srcz, link, date }) {
  // Function to truncate text to a specific word count
  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options).toUpperCase();
  };

  return (
    <BlogLink href={link} target="_blank" rel="noopener noreferrer">
      <WrapperBtn className="animate pointer">
        <Wrapper className="whiteBg radius8 shadow">
          <ImageWrapper>
            <img src={srcz} alt={title} />
          </ImageWrapper>
          <h3 style={{color:"#022D36"}} className="font20 extraBold">{title}</h3>
          <DateTag className="font13 extraBold">{formatDate(date)} 3 MIN READ</DateTag>
          <p className="font13" style={{ padding: "30px 0" }}>
            {truncateText(text, 20)}
          </p>

        </Wrapper>
      </WrapperBtn>
    </BlogLink>
  );
}

const BlogLink = styled.a`
  text-decoration: none;
  color: inherit;
  width: 30%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 150px;
    border-radius: 20px;
    object-fit: cover;
  }
`;

const DateTag = styled.p`
  margin-top: 10px;
  color: #777;
  
`;