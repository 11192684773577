import React from "react";
// Sections
import FaqUpperSection from "../components/Sections/FaqUpperSection"
import Question from "../components/Sections/Questions"
import Blog from "../components/Sections/Blog";

const Faqs= () => {
    return (
        <>
            <FaqUpperSection />
            <Question/>
            <Blog/>
          
        </>
    )
}

export default Faqs